import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get("/admin/conversations")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchForums() {
      return new Promise((resolve, reject) => {
        axios
          .get("/admin/conversations/forums")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addForum(ctx, forum) {
      return new Promise((resolve, reject) => {
        axios.post('/admin/conversations/forum/create', forum)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get("/admin/profile")
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    getChat(ctx, { conversationId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/conversation/${conversationId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    sendMessage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/admin/conversation/message/send`, payload)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
